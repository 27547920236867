/* client/src/components/Register.css */

.register-container {
  background: #f9f9f9;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.col-md-6 {
  flex: 1;
  max-width: 45%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

h2 {
  margin-bottom: 20px;
  color: #007bff;
}

.form-group {
  margin-bottom: 20px;
}

.btn {
  width: 100%;
}

.register-benefits {
  margin-top: 20px;
}

.register-benefits h4 {
  margin-bottom: 15px;
  color: #007bff;
}

.register-benefits ul {
  list-style-type: none;
  padding: 0;
}

.register-benefits ul li {
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .col-md-6 {
    max-width: 100%;
  }
}