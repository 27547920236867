.result-container {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.result-container h3 {
  margin: 0 0 10px 0;
}

.result-container p {
  margin: 0 0 10px 0;
}

.result-container ul {
  padding-left: 20px;
}

.result-container li {
  margin-bottom: 5px;
}

strong {
  font-weight: bold;
}