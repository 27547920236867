.subtopic-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.subtopic-header {
  text-align: center;
  margin-bottom: 20px;
}

.subtopic-header h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.subtopic-header p {
  font-size: 1.2em;
  color: #555;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.subtopic-header .centered-description {
  font-size: 1.1em;
  color: #666;
}

.back-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}

.viewpoint-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #007bff;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.toggle-label {
  margin-right: 10px;
  font-size: 1.1em;
  color: #333;
}

.media-section {
  margin-bottom: 20px;
  text-align: center;
}

.media-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.media-item {
  position: relative;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
}

.media-item h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.media-item p {
  font-size: 1em;
  color: #666;
}

.media-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.media-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.media-wrapper audio {
  width: 100%;
  border: 0;
}

.comments-section {
  margin-top: 30px;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.comments-section h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.comments-section ul {
  list-style-type: none;
  padding: 0;
}

.comments-section li {
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.comments-section strong {
  color: #333;
}

.comments-section form {
  margin-top: 20px;
}

.comments-section textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.comments-section button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.comments-section button:hover {
  background-color: #0056b3;
}

.comments-list {
  list-style-type: none;
  padding: 0;
}

.comment-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.comment-avatar {
  margin-right: 10px;
}

.comment-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.comment-content {
  background: #f9f9f9;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  font-size: 0.9em;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.comment-timestamp {
  color: #888;
  font-size: 0.9em;
}

.reply-item {
  margin-left: 15px;
  margin-top: 5px;
}

.reply-button {
  color: #007bff;
  cursor: pointer;
  font-size: 0.9em;
}

.reply-button:hover {
  font-weight: bold;
}

.comment-form,
.reply-form {
  margin-top: 5px;
}

.comment-form textarea,
.reply-form textarea {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  resize: vertical;
  font-size: 0.9em;
}

.comment-form button,
.reply-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.comment-form button:hover,
.reply-form button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .media-item {
    width: 100%;
  }
}

.videos-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.video-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.video-card:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .videos-grid {
    grid-template-columns: 1fr;
  }
}

.wider-container {
  max-width: 1200px;
  /* Adjust the width as needed */
  margin: 0 auto;
}