.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1e1e1e;
  /* Dark background for header */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c2c2c;
  /* Dark background for navbar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  padding: 0.5rem 1rem;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #ffffff;
  /* White text color for contrast */
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.nav-link:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
  /* Light hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px 10px;
}

.navbar-text {
  margin-right: 15px;
  color: #ffffff;
  /* Ensure navbar text is white */
}

.login-link {
  margin-left: auto;
  color: #ffffff;
  /* Ensure login link is white */
}

.nav-item .btn-link {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: #ffffff;
  /* Ensure button link is white */
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
}

.search-icon {
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 10px;
  color: #07cef6;
}

.search-input {
  transition: width 0.3s ease;
  width: 0;
  opacity: 0;
  color: #ffffff;
  /* Ensure search input text is white */
}

.search-input.expanded {
  width: 200px;
  opacity: 1;
}

@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .nav-link {
    padding: 10px 0;
    width: 100%;
  }

  .search-container {
    width: 100%;
    justify-content: center;
  }

  .search-input.expanded {
    width: 100%;
  }

  .login-link {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    text-align: left;
    order: 1;
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Ensure the footer is at the bottom */
.footer {
  background-color: #1e1e1e;
  /* Dark background for footer */
  color: #f1f1f1;
  text-align: center;
  padding: 0.5rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  margin-top: auto;
}

.footer p {
  margin: 0.5rem 0;
  color: #f1f1f1;
}

.footer a {
  color: #f1f1f1;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}