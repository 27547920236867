/* client/src/custom.css */

html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  color: #343a40;
}

.content {
  flex: 1;
  padding-bottom: 60px;
  /* Add padding to the bottom to make space for the footer */
}

.navbar {
  margin-bottom: 20px;
  border-bottom: 2px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand,
.nav-link {
  color: #007bff !important;
}

.navbar-brand:hover,
.nav-link:hover {
  color: #0056b3 !important;
}

.container h1 {
  color: #343a40;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.list-group-item {
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.list-group-item h5 {
  color: #007bff;
}

.list-group-item p {
  margin-bottom: 0;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

footer {
  background-color: #343a40;
  color: #ffffff;
  padding: 10px 0;
  text-align: center;
  position: relative;
  width: 100%;
}

.media-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thumbnail-wrapper {
  position: relative;
  cursor: pointer;
}

.thumbnail-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  opacity: 0.8;
}