/* client/src/components/Login.css */

.login-container {
  background: #f9f9f9;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 100px auto;
}

h2 {
  margin-bottom: 20px;
  color: #007bff;
}

.form-group {
  margin-bottom: 20px;
}

.btn {
  width: 100%;
}