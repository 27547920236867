.topic-detail-container {
  display: flex;
  flex-direction: column;
  /* Change to column for better mobile layout */
  align-items: center;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  /* White background for topic detail */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  /* Ensure the container does not exceed the viewport width */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}

.topic-content {
  flex: 1;
  margin-bottom: 20px;
  /* Add margin to separate from chatbot container */
  text-align: center;
  width: 100%;
  /* Ensure full width */
}

.topic-header {
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-align: center;
}

.topic-header h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #333;
  /* Dark text color for headings */
}

.centered-description {
  font-size: 1.2em;
  color: #555;
  text-align: center;
  max-width: 80%;
  /* Set a maximum width for the description */
  margin: 0 auto;
  /* Center the description */
}

.subtopics-container {
  margin-top: 20px;
}

.subtopics-container h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
  color: hsl(0, 0%, 20%);
  /* Dark text color for headings */
}

.subtopics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

.subtopic-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  color: #007bff;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.subtopic-card:hover {
  transform: scale(1.05);
}

.learn-more-button {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.learn-more-button:hover {
  text-decoration: underline;
}

.chatbot-container {
  flex: 1;
  background: #ffffff;
  /* White background for chatbot container */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Ensure full width */
}

.chatbot-header {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
  /* Dark text color for headings */
  text-align: center;
}

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.question-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.question-button:hover {
  background-color: #0056b3;
}

.answer-container {
  text-align: left;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.answer-container h4 {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #007bff;
}

.answer-container p {
  font-size: 1em;
  color: #333;
}

/* ...existing code... */

.comments-section {
  margin-top: 40px;
}

.comment-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.comment-form textarea {
  resize: none;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.comment-form button {
  align-self: flex-end;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.comment-form button:hover {
  background-color: #0056b3;
}

.comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 10px;
}

.comment-content {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.comment-author {
  font-weight: bold;
  margin-bottom: 5px;
}

.comment-text {
  margin: 0;
}

.missing-something {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
  color: #333;
}

@media (min-width: 769px) {
  .topic-detail-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
  }

  .topic-content {
    text-align: left;
  }

  .chatbot-container {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .topic-detail-container {
    flex-direction: column;
    align-items: center;
  }

  .topic-content,
  .chatbot-container {
    width: 100%;
    margin-right: 0;
  }

  .topic-header h2 {
    font-size: 2em;
  }

  .centered-description {
    font-size: 1em;
  }

  .subtopics-container h3,
  .chatbot-header {
    font-size: 1.2em;
  }

  .subtopic-card h4 {
    font-size: 1.2em;
  }

  .question-button {
    font-size: 0.9em;
    padding: 8px;
  }

  .answer-container h4 {
    font-size: 1em;
  }

  .subtopics-grid {
    grid-template-columns: 1fr;
  }
}