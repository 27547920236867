/* client/src/index.css */

nav {
  margin-bottom: 20px;
}

nav a {
  margin-right: 10px;
  text-decoration: none;
  color: rgb(9, 3, 179);
}

nav a:hover {
  text-decoration: underline;
}

.navbar {
  background-color: #ffffff !important;
  /* White color */
}

.navbar-logo {
  height: 40px;
  /* Adjust height as needed */
  margin-right: 10px;
  /* Space between logo and text */
}

.search-icon {
  color: #5bc8ffa1;
  /* Ensure silver color for magnifying glass */
}