.insights-section {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 800px;
}

.insights-section h2 {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 10px;
}

.insights-section p {
  font-size: 1.2em;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
}

.insights-list {
  margin-bottom: 30px;
}

.insight-item {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.insight-item h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.insight-item p {
  margin-bottom: 10px;
  color: #666;
}

.insight-item .read-more {
  color: #007bff;
  text-decoration: none;
}

.insight-item .read-more:hover {
  text-decoration: underline;
}

.submit-insight {
  margin-top: 30px;
}

.submit-insight h3 {
  text-align: center;
  margin-bottom: 20px;
}

.insight-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 500px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group textarea {
  resize: vertical;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.submit-button:hover {
  background-color: #0056b3;
}