/* styles/globals.css */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e0f7fa;
  /* Light blue background for body */
  height: 100%;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
}

h1,
h2 {
  font-size: 2em;
  color: #333;
  /* Dark text color for headings */
}

p {
  font-size: 1em;
  color: #555;
  max-width: 100%;
  margin: 20px 0;
}

.btn {
  padding: 10px 20px;
  font-size: 1.1em;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.benefits-list li {
  background: #ffffff;
  /* White background for list items */
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1em;
}

.form-control {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.25em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group label {
  font-weight: bold;
}

/* Navbar styling */
.navbar {
  padding: 0.5rem 1rem;
  background-color: #2c2c2c;
  /* Dark background for navbar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.navbar-brand img {
  max-height: 40px;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.navbar-logo {
  max-height: 40px;
  margin-right: 10px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.nav-link.login-link {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  h1,
  h2 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.9em;
  }

  .btn {
    font-size: 1em;
    padding: 8px 16px;
  }
}