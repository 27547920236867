.topics-container {
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
}

.topic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  position: relative;
  width: 100%;
  z-index: 1;
}

.topic-item {
  background-color: #ffffff;
  /* Match the color of the box at the topic level */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  width: 100%;
  box-sizing: border-box;
}

.topic-item:hover {
  transform: scale(1.05);
}

.topic-item h5 {
  margin-bottom: 10px;
  font-size: 1.25em;
  color: #333;
  /* Dark text color for headings */
}

.topic-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.topic-link:hover {
  color: #0056b3;
}

.subtopic-link {
  color: #007bff;
  text-decoration: none;
  display: block;
  margin-top: 10px;
}

.subtopic-link:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
}

hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.tagline {
  text-align: center;
  font-size: 2em;
  margin: 5px 0;
  /* Adjusted margin */
  color: #007bff;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.tagline-container {
  background-color: #ffffff;
  padding: 10px 10px 0px 10px;
  /* Adjusted padding */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.missing-something-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.missing-something {
  font-size: 1.5em;
  color: #007bff;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.missing-something a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.missing-something a:hover {
  text-decoration: underline;
  color: #0056b3;
}

@media (max-width: 1200px) {
  .topic-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .topic-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .topic-item {
    padding: 15px;
  }

  .topic-item h5 {
    font-size: 1em;
  }

  .subtopic-link {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .topic-grid {
    grid-template-columns: 1fr;
  }

  .topic-item {
    padding: 10px;
  }

  .topic-item h5 {
    font-size: 0.9em;
  }

  .subtopic-link {
    font-size: 0.8em;
  }
}

.registration-wall {
  margin-top: 2rem;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registration-wall-content {
  max-width: 600px;
  margin: 0 auto;
}

.registration-wall h3 {
  color: #333;
  margin-bottom: 1rem;
}

.registration-wall p {
  color: #666;
  margin-bottom: 1.5rem;
}

.registration-wall-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.registration-wall-buttons .btn {
  padding: 0.5rem 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  white-space: nowrap;
}

/* Add a fade effect to the last row when not logged in */
.topic-grid::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, transparent, #fff);
  pointer-events: none;
  display: none;
}

/* Only show the fade effect when not logged in and there are hidden topics */
.topic-grid.has-hidden-topics::after {
  display: block;
}

.topic-item.locked {
  opacity: 0.7;
  position: relative;
  z-index: 1;
}

.disabled-link {
  pointer-events: none;
  color: #6c757d;
  text-decoration: none;
}

.registration-wall-overlay {
  position: absolute;
  top: calc((250px + 1.5rem) * 3);
  left: 0;
  right: 0;
  height: calc(100% - ((250px + 1.5rem) * 3));
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.6) 20%);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding-top: 2rem;
  min-height: 300px;
}

.registration-wall-content {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  z-index: 3;
}

.registration-wall-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.registration-wall-buttons .btn {
  padding: 0.5rem 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}