/* Mission page container */
.mission-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #f4f6f8;
  font-family: 'Arial, sans-serif';
}

/* Flex container for Mission Statement and Guiding Principles */
.mission-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
}

/* Individual sections */
.mission-section {
  background: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.mission-statement {
  flex: 1.5;
}

.guiding-principles {
  flex: 1;
}

/* Headers and text styling */
.mission-section h2,
.mission-section h3 {
  font-size: 2em;
  color: #007bff;
  margin-bottom: 20px;
}

.mission-section h4 {
  font-size: 1.5em;
  color: #007bff;
  margin-bottom: 10px;
}

.mission-section p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}

/* FAQ section */
.faq-container {
  background: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-top: 40px;
}

.faq-section {
  width: 100%;
}

.faq-section h2 {
  font-size: 2em;
  color: #007bff;
  margin-bottom: 20px;
  font-weight: bold;
}

.faq-question {
  background: #007bff;
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: bold;
}

.faq-answer {
  background: #f4f6f8;
  padding: 15px;
  border-radius: 8px;
  display: none;
  margin-bottom: 20px;
}

.faq-question.active+.faq-answer {
  display: block;
}

/* Responsive design for smaller screens */
@media (max-width: 1024px) {
  .mission-flex-container {
    flex-direction: column;
  }

  .mission-section {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .mission-section {
    padding: 20px;
  }

  .mission-section h2,
  .mission-section h3 {
    font-size: 1.8em;
  }

  .mission-section p {
    font-size: 1.1em;
  }
}